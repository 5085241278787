<template>
  <div class="auth-wrapper auth-v2 complete-profile-page-class">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          alt="logo"
          class="logo-size-class"
          src="@/assets/images/logo/simi-connect-logo.png"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        class="d-none d-lg-flex align-items-center p-5"
        lg="8"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            :src="imgUrl"
            alt="Login V2"
            fluid
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        class="d-flex align-items-center auth-bg px-2 p-lg-2"
        lg="4"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            class="d-inline-block d-flex justify-content-center align-content-center heading1 align-items-center mt-1 ml-1"
          >
            Complete Profile
          </div>
          <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center mt-1 ml-1">
            <b-img
              :src="userImgSrc ? userImgSrc : require('@/assets/images/simiicons/signup.svg')"
              alt="data.json"
              rounded="circle"
              :class="userImgSrc ? 'user-img' : null "
            />
            <span class="upload">
              <b-button
                variant="link"
                class="icon-class"
                @click="inputFileClicked"
              >
                <b-img
                  :src="require('@/assets/images/simiicons/signupbtn.svg')"
                  rounded
                  alt="data.json"
                />
              </b-button>
            </span>
          </div>
          <!-- form -->
          <b-form class="auth-register-form mt-2">
            <!-- username -->
            <b-form-group
              label="Name"
              class="input-label"
              label-for="select-user-name"
            >
              <b-form-input
                id="select-user-name"
                v-model="dataToBeSaved.name"
                name="user-name"
                placeholder="Enter Name here"
              />
            </b-form-group>
            <input
              id="fileUpload"
              type="file"
              accept="image/*"
              hidden
              @change="uploadImage($event)"
            >
            <!-- <span class="datePickerDiv">
              <b-form-group
                label="Date of Birth"
                class="input-label "
                label-for="datepicker"
              >
                <datepicker
                  id="datepicker"
                  v-model="dataToBeSaved.date_of_birth"
                  placeholder="Please select your date of birth"
                  :format="customFormatter"
                  :open-date="openDate"
                  use-utc
                  fixed-position="top"
                  :disabled-dates="dataToBeSaved.disabledDates"
                  wrapper-class="custom-datepicker-class"
                  input-class="my-picker-class"
                  :bootstrap-styling="true"
                  calendar-class="calendar-class"
                  class=" mb-1"
                  @selected="dateChangeHandler(dataToBeSaved.date_of_birth)"
                />
              </b-form-group>
            </span> -->
            <div class="form-group block">
              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="!active"
                @click.prevent="completeProfileOfUser"
              >
                <div
                  v-if="spinner"
                  class="spinner"
                >
                  <b-spinner
                    small
                  />
                  <span class="sr-only">Loading...</span>
                </div>
                Create Account
              </b-button>
            </div>
          </b-form>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>
<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BImg,
  BSpinner,
  BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import moment from 'moment'
import store from '@/store'
import { getFormDataFromJSON, showToast } from '@/common/global/functions'
// import Datepicker from 'vuejs-datepicker'

export default {
  name: 'CompleteProfile',
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormGroup,
    BSpinner,
    BFormInput,
    // Datepicker,
  },
  async beforeRouteEnter(to, from, next) {
    const user = store.getters['user/getUser']
    if (user.is_profile_completed) {
      next({
        name: 'groups-listing',
      })
      return
    }
    next()
  },
  data() {
    return {
      dateLimit: moment(Date.now()).format('YYYY-MM-DD'),
      openDate: new Date('1994-01-01'),
      username: '',
      userEmail: '',
      userImage: '',
      userImgSrc: '',
      password: '',
      confirmPassword: '',
      active: true,
      spinner: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      required,
      dataToBeSaved: {
        name: null,
        gender: null,
        date_of_birth: '1990-01-01',
        image: null,
        disabledDates: {
          from: new Date(2010, 0, 1),
        },
      },
      genderOptions: [{
        value: null,
        text: 'Please select your gender',
        disabled: true,
      },
      {
        value: 1,
        text: 'Male',
      },
      {
        value: 2,
        text: 'Female',
      },
      {
        value: 3,
        text: 'Others',
      },
      ],
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    dateChangeHandler(date) {
      this.dataToBeSaved.date_of_birth = moment(date).format('YYYY-MM-DD')
    },
    // dateDisabled(ymd, date) {
    //   return moment(date).format('YYYY-MM-DD') >= moment(Date.now()).format('YYYY-MM-DD')
    // },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    inputFileClicked(event) {
      document.getElementById('fileUpload').click(event)
    },
    uploadImage(event) {
      const obj = event.target.files[0]
      this.dataToBeSaved.image = obj
      this.userImgSrc = URL.createObjectURL(obj)
    },
    async completeProfileOfUser() {
      this.dataToBeSaved.gender = 1
      if (!this.dataToBeSaved.name) {
        showToast('Complete Profile', 'Please enter your name!', 'danger', 4000)
        return
      }
      if (this.dataToBeSaved.date_of_birth !== null) {
        this.dataToBeSaved.date_of_birth = moment(this.dataToBeSaved.date_of_birth).format('YYYY-MM-DD')
      }
      this.spinner = true
      try {
        const formData = getFormDataFromJSON(this.dataToBeSaved)
        const { data: { data } } = await this.$axios.post('user/complete-profile', formData)
        await this.$store.dispatch('user/setUser', data)
        try {
          await this.$router.push('/groups-listing')
        } catch (e) {
          //
        }
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        showToast('Complete Profile', message.toString(), 'danger')
      }
      this.spinner = false
    },
  },
}
</script>

<style lang="scss">
@import "../src/@core/scss/vue/pages/page-auth";

.complete-profile-page-class {
  .subheading {
    font-size: 14px;
    line-height: 30px;
    color: #3d3d3d;
    font-weight: 400;
    font-family: "Montserrat";
  }

  .heading1 {
    // width: 277px;
    height: 30px;
    font-size: 20px;
    color: #3d3d3d;
    font-weight: 700;
    font-family: "Montserrat";
  }

  .upload {
    margin-left: -30px;
    margin-bottom: -40px;
  }

  .icon-class {
    padding: 0px !important;
  }

  .input-label {
    font-size: 10px;
    line-height: 18px;
    color: #6e6b7b !important;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .user-img {
    width: 140px;
    height: 140px;
  }

  .datePickerDiv {
    .custom-datepicker-class {
      display: inline-block !important;
      width: 100% !important;
      height: 2.714rem !important;
      font-size: 1rem !important;
      font-weight: 400 !important;
      line-height: 1.45 !important;
      color: #6e6b7b !important;
      vertical-align: middle !important;
    }

    .calendar-class {
      position: absolute;
      bottom: 0;
      span.selected{
        background: #1f58b5;
        color:white;
      }
      span.cell:not(.blank):hover{
        border: 1px solid #1f58b5;
      }
    }

    .vdp-datepicker__calendar {
      width: 300px;
      @media (max-width: 848px) {
        width: 250px !important;
      }
    }

    .my-picker-class {
      padding: 0.438rem 2rem 0.438rem 1rem !important;
      display: inline-block !important;
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;
      width: 100% !important;
    }

    input {
      padding: 0.5rem 1rem;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #d8d6de !important;
      border-radius: 0.357rem;
      width: 100%;
      font-weight: 400;
      font-family: "Montserrat";
    }
  }

  ::placeholder {
    color: #6e6b7b;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #6e6b7b;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #6e6b7b;
  }
}
</style>
